import Axios, { DownloadAxios, GeneralAxios } from 'utils/axios';
import { authErrorMessageInfo } from './error-message/auth';
import { chotCaErrorMessageInfo } from './error-message/chot-ca';
import { complaintErrorMessageInfo } from './error-message/complaint';
import { hrErrorMessageInfo } from './error-message/hr-error';
import { mktErrorMessageInfo } from './error-message/mkt';
import { serviceErrorMessageInfo } from './error-message/service';
import { timekeepingErrorMessageInfo } from './error-message/timekeeping-error';
import { wareHouseErrorMessageInfo } from './error-message/ware-house';
import { zaloPointErrorMessageInfo } from './error-message/zalo-point';
import { inventoryErrorMessageInfo } from './error-message/inventory';
import { automationPyErrorMessageInfo } from './error-message/automation-py';
import { cronJobErrorMessageInfo } from './error-message/cron-job';

export enum ServiceRoute {
  Hr = 'hr',
  Timekeeping = 'time-keeping/manage',
  MktHelpers = 'mkt-helpers',
  ComplaintApi = 'complaint/manage',
  ZaloPointApi = 'zalo/admin',
  // ChotCaApi = 'chotca-dev/api',
  ChotCaApi = 'chotca/api',
  Auth = 'auth',
  Service = 'service',
  // WareHouse = 'kho-hang-dev',
  WareHouse = 'ware-house',
  Inventory = 'inventory',
  AutomationPython = 'automation-py',
  CronJobApi = 'cron-job/api'
}

export const API_INFO: Record<ServiceRoute, Record<string, string>> = {
  hr: hrErrorMessageInfo,
  'time-keeping/manage': timekeepingErrorMessageInfo,
  'mkt-helpers': mktErrorMessageInfo,
  'complaint/manage': complaintErrorMessageInfo,
  'zalo/admin': zaloPointErrorMessageInfo,
  'chotca/api': chotCaErrorMessageInfo,
  auth: authErrorMessageInfo,
  service: serviceErrorMessageInfo,
  // 'kho-hang-dev': wareHouseErrorMessageInfo,
  'ware-house': wareHouseErrorMessageInfo,
  inventory: inventoryErrorMessageInfo,
  'automation-py': automationPyErrorMessageInfo,
  'cron-job/api': cronJobErrorMessageInfo
};

export const hrAxios = new GeneralAxios(ServiceRoute.Hr);
export const mktAxios = new GeneralAxios(ServiceRoute.MktHelpers);
export const timekeepingAxios = new GeneralAxios(ServiceRoute.Timekeeping);
export const wareHouseAxios = new GeneralAxios(ServiceRoute.WareHouse);
export const complaintAxios = new GeneralAxios(ServiceRoute.ComplaintApi);
export const zaloPointAxios = new GeneralAxios(ServiceRoute.ZaloPointApi);
export const chotCaAxios = new GeneralAxios(ServiceRoute.ChotCaApi);
export const authAxios = new GeneralAxios(ServiceRoute.Auth);
export const serivceAxios = new GeneralAxios(ServiceRoute.Service);
export const inventoryAxios = new GeneralAxios(ServiceRoute.Inventory);
export const automationPyAxios = new GeneralAxios(ServiceRoute.AutomationPython);
export const cronJobAxios = new GeneralAxios(ServiceRoute.CronJobApi).instance;

export const DOWNLOAD_AXIOS: Partial<Record<ServiceRoute, Axios>> = {
  hr: new DownloadAxios(ServiceRoute.Hr),
  'time-keeping/manage': new DownloadAxios(ServiceRoute.Timekeeping),
  'mkt-helpers': new DownloadAxios(ServiceRoute.MktHelpers),
  'complaint/manage': new DownloadAxios(ServiceRoute.ComplaintApi),
  'zalo/admin': new DownloadAxios(ServiceRoute.ZaloPointApi),
  'chotca/api': new DownloadAxios(ServiceRoute.ChotCaApi),
  auth: new DownloadAxios(ServiceRoute.Auth),
  service: new DownloadAxios(ServiceRoute.Service),
  // 'kho-hang-dev': new DownloadAxios(ServiceRoute.WareHouse),
  'ware-house': new DownloadAxios(ServiceRoute.WareHouse),
  inventory: new DownloadAxios(ServiceRoute.Inventory)
};
