import {
  ApartmentOutlined,
  BugOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  ContainerOutlined,
  ShoppingOutlined,
  UsergroupAddOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const humanResourcesManagement: NavItemType = {
  id: 'hr',
  title: 'Nhân sự',
  type: 'collapse',
  icon: UsergroupAddOutlined,
  children: [
    {
      id: 'employee',
      title: 'Nhân viên',
      type: 'item',
      icon: UsergroupAddOutlined,
      url: ROUTE_URL.hr.employee.list,
      permission: PERMISSIONS.employee
    },
    {
      id: 'timekeeping-history',
      title: 'Lịch sử chấm công',
      type: 'item',
      icon: ClockCircleOutlined,
      url: ROUTE_URL.hr.timekeepingHistory,
      permission: PERMISSIONS.timekeepingManage
    },
    {
      id: 'timekeeping-ticket',
      title: 'Quên công và Thêm ca',
      type: 'item',
      icon: ContainerOutlined,
      url: ROUTE_URL.hr.timekeepingTickets.list,
      permission: PERMISSIONS.timekeepingManage
    },
    {
      id: 'on-leave',
      title: 'Phiếu nghỉ phép',
      type: 'item',
      icon: CoffeeOutlined,
      url: ROUTE_URL.hr.onLeave.list,
      permission: PERMISSIONS.timekeepingManage
    },
    {
      id: 'violation',
      title: 'Phiếu sai phạm',
      type: 'item',
      icon: WarningOutlined,
      url: ROUTE_URL.hr.violation.list,
      permission: PERMISSIONS.timekeepingManage
    },
    {
      id: 'department',
      title: 'Phòng ban',
      type: 'item',
      icon: ApartmentOutlined,
      url: ROUTE_URL.hr.department,
      permission: PERMISSIONS.department
    },
    {
      id: 'position',
      title: 'Chức vụ',
      type: 'item',
      icon: ShoppingOutlined,
      url: ROUTE_URL.hr.position,
      permission: PERMISSIONS.position
    },
    {
      id: 'system-timekeeping',
      title: 'Chấm hệ thống',
      type: 'item',
      icon: BugOutlined,
      url: ROUTE_URL.hr.systemTimekeepingTickets.list,
      permission: PERMISSIONS.systemTimekeepingManage
    }
  ]
};

export default humanResourcesManagement;
