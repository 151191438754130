export const KEYS = {
  localStorageKey: {
    accessToken: 'access_token_hr_tgw',
    refreshToken: 'refresh_token_hr_tgw'
  },
  app: {
    redirectAfterLogin: 'redirect_url'
  },
  redux: {
    authUser: 'auth_hr_tgw'
  },
  query: {
    me: 'me',
    employee: 'employee',
    location: 'location',
    position: 'position',
    role: 'role',
    department: 'department',
    resource: 'resource',
    meRole: 'me-role',
    shiftType: 'shift-type',
    timekeepingTickets: 'timekeeping-tickets',
    timekeeping: 'timekeeping',
    systemTimekeeping: 'system-timekeeping',
    onLeave: 'on-leave',
    violation: 'violation',
    violationPoint: 'violation-point',
    mkt: {
      productLabel: 'product-label',
      productManagement: 'product-management',
      internalProductManagement: 'internal-product-management',
      retailReportByChanel: 'retail-report-by-chanel',
      fbPostResponse: 'fb-post-response'
    },
    orderNote: 'order-note',
    complaint: 'complaint',
    zaloPointPromotion: 'zalo-point-promotion',
    inStoreSalesReport: 'in-store-sales-report',
    zalo: {
      promotionTags: 'zalo/promotion-tags',
      uiSettings: 'zalo-ui-settings',
      news: 'zalo-news',
      miniAppLibrary: 'mini-app-library'
    },
    ecommerceOrder: 'ecommerce-order',
    ecommerceGift: 'ecommerce-gift',
    service: 'service',
    wareHouse: {
      deliveryBatches: 'ware-house/delivery-batch',
      inventory: 'ware-house/inventory',
      exportOrder: 'ware-house/export-order',
      printOrder: 'ware-house/print-order',
      printOrderBatch: 'ware-house/print-order-batch',
      printHistory: 'ware-house/print-history',
      ecommerceOrder: {
        exportBatch: 'ware-house/ecommerce-export-batch',
        simpleExportBatch: 'ware-house/ecommerce-simple-export-batch',
        returnedBatch: 'ware-house/ecommerce-returned-batch'
      }
    },
    mattermostAccount: 'matermost-account',
    tiktokAuth: 'tiktok-auth',
    accounting: {
      cashbook: 'cash-book',
      revenueDayByDay: 'revenue-day-by-day'
    },
    system: {
      cronJob: 'system/cron-job',
      jiraCronJob: 'system/jira-cron-job'
    }
  }
};
