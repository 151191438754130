import moment from 'moment';
import numeral from 'numeral';

export default class FormatUtils {
  static formatNumber(value: any) {
    return numeral(value).format('0,0');
  }

  static formatTime(data: any, format: string = 'DD/MM/YYYY') {
    return data ? moment.utc(data).format(format) : '';
  }

  static formatTimeVN(data: any, format: string = 'DD/MM/YYYY') {
    return data ? moment.utc(data).add(7, 'hour').format(format) : '';
  }

  static cutOffSecond(value: string | null) {
    if (!value) return '';
    const [hours, minutes] = value.split(':');
    return `${hours}:${minutes}`;
  }
}
