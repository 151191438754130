export const hrRoutes = {
  employee: {
    list: '/nhan-su/nhan-vien',
    goToDetail: (id: number | string) => `/nhan-su/nhan-vien/${id}`,
    add: '/nhan-su/nhan-vien/tao-moi'
  },
  department: '/nhan-su/phong-ban',
  position: '/nhan-su/chuc-vu',
  timekeepingTickets: {
    list: '/nhan-su/phieu-cham-cong',
    detail: '/nhan-su/phieu-cham-cong/:id',
    goToDetail: (id: number | string) => `/nhan-su/phieu-cham-cong/${id}`
  },
  systemTimekeepingTickets: {
    list: '/nhan-su/cham-cong-he-thong',
    detail: '/nhan-su/cham-cong-he-thong/:id',
    goToDetail: (id: number | string) => `/nhan-su/cham-cong-he-thong/${id}`
  },
  timekeepingHistory: '/nhan-su/lich-su-cham-cong',
  onLeave: {
    list: '/nhan-su/phieu-nghi-phep',
    detail: '/nhan-su/phieu-nghi-phep/:id',
    goToDetail: (id: number | string) => `/nhan-su/phieu-nghi-phep/${id}`
  },
  violation: {
    list: '/nhan-su/phieu-sai-pham',
    detail: '/nhan-su/phieu-sai-pham/:id',
    goToDetail: (id: number | string) => `/nhan-su/phieu-sai-pham/${id}`,
    create: '/nhan-su/phieu-sai-pham/tao-sai-pham'
  }
};
