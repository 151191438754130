import Loadable from 'components/Loadable';
import { lazy } from 'react';
import { RouteObject } from 'react-router';

const ProfilePage = Loadable(lazy(() => import('pages/account/profile')));
const ChangePasswordPage = Loadable(lazy(() => import('pages/account/change-password')));

const MeRoutes: RouteObject[] = [
  {
    index: true,
    element: <ProfilePage />
  },
  {
    path: 'doi-mat-khau',
    element: <ChangePasswordPage />
  }
];

export default MeRoutes;
