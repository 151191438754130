import { cronJobAxios } from 'constants/api';
import {
  CronJob,
  CronJobBodyData,
  CronJobDetailResponse,
  CronJobsListResponse,
  EditJiraCronJobBody,
  JiraCronJobDetail,
  JiraCronJobsListResponse,
  JiraType,
  JiraUser,
  RunningJob
} from 'modules/cron-job/interface';
import queryString from 'query-string';

export default class CronJobApi {
  static async getJiraIssueTypes(): Promise<{ jira_types: JiraType[] }> {
    return cronJobAxios.get('/jira-issue/types');
  }

  static async getJiraIssueUsers(): Promise<{ jira_users: JiraUser[] }> {
    return cronJobAxios.get('/jira-issue/users');
  }

  static async getCronJobs(query: any): Promise<CronJobsListResponse> {
    return cronJobAxios.get(`/cron-job?${queryString.stringify(query)}`);
  }

  static async getCronJob(id: number | string | undefined): Promise<CronJobDetailResponse> {
    return cronJobAxios.get(`/cron-job/${id}`);
  }

  static async updateCronJobStatus({ id, is_active }: { id: number; is_active: boolean }): Promise<{ cron_job: CronJob }> {
    return cronJobAxios.patch(`/cron-job/${id}/activate`, { is_active });
  }

  static async createCronJob(data: CronJobBodyData) {
    return cronJobAxios.post('/cron-job', data);
  }

  static async updateCronJob({ id, data }: { id: number; data: CronJobBodyData }) {
    return cronJobAxios.put(`/cron-job/${id}`, data);
  }

  static async getJobRunning(): Promise<{ cron_jobs: RunningJob[] }> {
    return cronJobAxios.get(`/cron-job/running`);
  }

  static async getJiraCronJobs(query: any): Promise<JiraCronJobsListResponse> {
    return cronJobAxios.get(`/jira-issue?${queryString.stringify(query)}`);
  }

  static async getJiraCronJob(id: number | string | undefined): Promise<{ jira_issue: JiraCronJobDetail }> {
    return cronJobAxios.get(`/jira-issue/${id}`);
  }

  static async createJiraCronJob(data: EditJiraCronJobBody) {
    return cronJobAxios.post('/jira-issue', data);
  }

  static async updateJiraCronJobStatus({ id, is_active }: { id: number; is_active: boolean }) {
    return cronJobAxios.patch(`/jira-issue/${id}/activate`, { is_active });
  }

  static async updateJiraCronJob({ id, data }: { id: number; data: EditJiraCronJobBody }) {
    return cronJobAxios.put(`/jira-issue/${id}`, data);
  }

  static async deleteJiraCronJob(id: number) {
    return cronJobAxios.delete(`/jira-issue/${id}`);
  }

  static async generateCronRule(description: string): Promise<{ rule: string; description: string }> {
    return cronJobAxios.post('/cron-job/generate-rule', { description }, { timeout: 20000 });
  }
}
