export const marketingRoutes = {
  productManagement: '/marketing/quan-ly-san-pham',
  fbChatbotResponseManagement: '/marketing/quan-ly-chatbot-fb',
  menuGenerator: '/marketing/menu-generator',
  miniApp: {
    promotionList: '/marketing/zalo-mini-app/promotion',
    UIManagement: '/marketing/zalo-mini-app/quan-ly-ui',
    tag: {
      tagManagement: '/marketing/zalo-mini-app/quan-ly-tag',
      goToTagManagementDetail: (id: string | number) => `/marketing/zalo-mini-app/quan-ly-tag/${id}`
    },
    news: {
      list: '/marketing/zalo-mini-app/tin-tuc',
      add: '/marketing/zalo-mini-app/tin-tuc/them',
      detail: '/marketing/zalo-mini-app/tin-tuc/:id',
      goToDetail: (id: number | string) => `/marketing/zalo-mini-app/tin-tuc/${id}`
    }
  }
};
