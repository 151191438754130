export const PERMISSIONS = {
  employee: 'employee',
  department: 'department',
  location: 'location',
  position: 'position',
  resource: 'resource',
  role: 'role',
  timekeepingManage: 'timekeeping-manage',
  customerComplaint: 'customer-complaint',
  systemTimekeepingManage: 'system-timekeeping',
  orderNote: 'order-note',
  promotion: 'promotion',
  saleReport: 'sale-report',
  miniAppSettings: 'mini-app-settings',
  ecommerceGift: 'ecommerce-gift',
  productManagement: 'product-management',
  chatbotFBManagement: 'fb-chatbot-manage',
  miniAppNews: 'mini-app-news',
  accountingReport: 'accounting-report',
  service: 'service',
  packing: 'packing',
  inventory: 'inventory',
  tiktokAuth: 'tiktok-auth',
  crawlData: 'crawl-data',
  fetchScheduler: 'fetch-scheduler',
  cronJob: 'cron-job',
  jiraIssueCronJob: 'cron-job-jira-issue',
  ecommerceOrderPrint: 'ecommerce-order-print'
};
