import { DeploymentUnitOutlined, MobileOutlined, ProductOutlined, GiftOutlined, ToolOutlined } from '@ant-design/icons';
import { PERMISSIONS } from 'constants/permission';
import { ROUTE_URL } from 'constants/route';
import { NavItemType } from 'types/menu';

const marketingManagement: NavItemType = {
  id: 'marketing',
  title: 'Marketing',
  type: 'collapse',
  icon: DeploymentUnitOutlined,
  children: [
    {
      id: 'zalo-mini-app',
      title: 'Zalo Mini App',
      type: 'collapse',
      icon: MobileOutlined,
      children: [
        {
          id: 'promotion',
          title: 'Promotion',
          type: 'item',
          url: ROUTE_URL.marketing.miniApp.promotionList,
          permission: PERMISSIONS.promotion
        },
        {
          id: 'promotion-tags',
          title: 'Quản lý nhóm quà',
          type: 'item',
          permission: PERMISSIONS.miniAppSettings,
          url: ROUTE_URL.marketing.miniApp.tag.tagManagement
        },
        {
          id: 'news',
          title: 'Quản lý Tin tức',
          type: 'item',
          url: ROUTE_URL.marketing.miniApp.news.list,
          permission: PERMISSIONS.miniAppSettings
        },
        {
          id: 'ui-management',
          title: 'Quản lý UI',
          type: 'item',
          url: ROUTE_URL.marketing.miniApp.UIManagement,
          permission: PERMISSIONS.miniAppSettings
        }
      ]
    },
    {
      id: 'quan-ly-san-pham',
      title: 'Quản lý sản phẩm',
      type: 'item',
      icon: ProductOutlined,
      url: ROUTE_URL.marketing.productManagement,
      permission: PERMISSIONS.productManagement
    },
    {
      id: 'quan-ly-chatbot-fb',
      title: 'Quản lý Chatbot FB',
      type: 'item',
      icon: GiftOutlined,
      url: ROUTE_URL.marketing.fbChatbotResponseManagement,
      permission: PERMISSIONS.chatbotFBManagement
    },
    {
      id: 'menu-generator',
      title: 'Tạo blog menu',
      type: 'item',
      icon: ToolOutlined,
      url: ROUTE_URL.marketing.menuGenerator
    }
  ]
};

export default marketingManagement;
