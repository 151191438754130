import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import CronJobApi from 'api/cron-job';
import { Column } from 'react-table';
import { RunningJob } from '../interface';
import FormatUtils from 'utils/format';
import MyTable from 'components/MyTable';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorRender from 'components/ErrorRender';
import withPermission from 'hoc/withPermission';
import { PERMISSIONS } from 'constants/permission';

function RunningCronJobPage() {
  const { data: runningJobs = [], isFetching: isLoadingRunningJobs } = useQuery({
    queryKey: ['cron-job-running'],
    queryFn: CronJobApi.getJobRunning,
    select(data) {
      return data.cron_jobs;
    }
  });

  const runningColumns: Column<RunningJob>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      disableSortBy: true,
      Cell: ({
        value,
        row: {
          original: { type }
        }
      }) => (
        <Typography>
          {type}_{value}
        </Typography>
      )
    },
    {
      Header: 'Loại',
      accessor: 'type',
      disableSortBy: true
    },
    {
      Header: 'Tên',
      accessor: 'name',
      disableSortBy: true
    },
    {
      Header: 'Thời gian thực hiện',
      accessor: 'rule_description',
      disableSortBy: true
    },
    {
      Header: 'Thời gian bắt đầu chạy',
      accessor: 'started_at',
      disableSortBy: true,
      Cell: ({ value }) => <Typography>{FormatUtils.formatTimeVN(value, 'YYYY/MM/DD  HH:mm')}</Typography>
    },
    {
      Header: 'Thời gian chạy lần cuối',
      accessor: 'last_run_at',
      disableSortBy: true,
      Cell: ({ value }) => <Typography>{FormatUtils.formatTimeVN(value, 'YYYY/MM/DD  HH:mm')}</Typography>
    }
  ];

  return (
    <>
      <Typography variant="h4">Danh sách job đang chạy</Typography>
      <MyTable hiddenPagination data={runningJobs} columns={runningColumns} loading={isLoadingRunningJobs} />
    </>
  );
}

export default withErrorBoundary(withPermission(RunningCronJobPage, PERMISSIONS.cronJob), { FallbackComponent: ErrorRender });
